.home-container {
    padding: 0px 7%;
}

.home-carousel {
    padding-top: 25px;
}

.welcome-album {
    max-height: 450px
}