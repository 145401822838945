.photo-gallery {
    width: auto;
    max-width: 90%;
}

.carousel {
    margin: auto;
}

.carousel-image {

}
