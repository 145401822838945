#menu-item {
    margin-right: 8%;
    font-size:calc(6px + 1.6vw);
    color: white;
}

#app-container {
    background-color: black;
    color: white;
}

.footer-custom {
    position: relative;
    left:0;
    bottom:0;
    width:100%;
    height:60px;  
    margin-top: 50px;
}

.footer-text {
    font-size: 14px;
    color: white;
    font-weight: 100;
}

@font-face {
    font-family: 'Bauhaus93';
    font-style: normal;
    font-weight: 400;
    src: url('./font/Bauhaus93.ttf');   
}

html, body {
    height: 100%;
}
body {
    background-color: black;
    font-family: 'Bauhaus93';
}

.container-custom {
    background-color: black;
    width: 100vw;
    min-height: 100vh;
    color: white;
    text-align: center;
}

.subheader {
    margin-top: 15px;
    font-size:calc(14px + 2.5vw);
    color: #fbe8d7;
}

.nav-style-custom {
    justify-content: flex-start;
    text-align: flex-start;
    margin-left: 5%;
}

#band-logo {
    margin-left: 15%;
    margin-top: 8%;
    border-radius: 20%;
    -webkit-transition: -webkit-transform .8s ease-in-out;
            transition:         transform .8s ease-in-out;
  }
#band-logo:hover {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

#nav-brand-container {
    margin-right: 21%;
}


