.press-headline {
    
}

.press-image {
    height: 65%;
}

.press-date {
    
}

.press-detail {
   

}
.press-anchor {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    color: red;
    
}

.press-grid {
    margin-top: 30px;
}

.image-col {
}

.text-col {
    
}
.text-col {
    align-items: flex-end;
    justify-content: center;
}

.press-image {
    margin: auto;
    max-width: 100%;
    max-height: 175px;
}


.press-row {
    max-height: 20%;
}

.press-headline {
    margin: auto;
    font-size:calc(14px + 1.5vw);
    color: orangered;
}

.press-date {

}

.press-detail {

}

.press-grid {
    margin-top: 75px;
}