.email-container {
    padding-top: 18%;
    padding-bottom: 20%;
    width: auto;
    align-items: center;
    justify-content: center;
}

.email-heading {
    color: orangered;
    font-size:calc(14px + 1.5vw);

}

.email-detail {
    color: white;
    font-size:calc(16px + 1.5vw);
}